<template>
  <div>
    <div class="justify-content-between py-3 d-flex d-lg-none">
      <div class="">
        {{
          capitalize(
            transChoice('general.results', paginator.count, {
              x: paginator.count,
            })
          )
        }}
      </div>

      <!-- Mobile menu -->
      <div class="d-lg-none d-flex flex-column justify-content-end align-items-start">
        <!-- <b-button
          variant="outline-gray"
          class="w-100"
          :class="isFiltersOpen ? 'text-white bg-dark' : 'text-dark bg-white'"
          @click="isFiltersOpen = !isFiltersOpen"
        >
          <i class="far fa-bars-sort" />
        </b-button> -->
        <b-button
          variant="outline-gray"
          :class="isMapShown ? 'text-white bg-dark' : 'text-dark bg-white'"
          @click="(v) => $emit('update:is-map-shown', !isMapShown)"
        >
          <i class="far fa-map" />
        </b-button>
      </div>
      <!-- Mobile menu -->
    </div>
    <search-cards
      v-bind="$attrs"
      source-property="results"
    />
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields';
import SearchCards from '../SearchCards.vue';

export default {
  name: 'CenterSearchCards',
  components: { SearchCards },
  props: { isMapShown: { type: Boolean, required: true } },
  emits: ['update:is-map-shown'],
  computed: {
    ...mapFields('map', ['paginator']),
  },
};
</script>
